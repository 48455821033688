export enum ExceptionMessageCode {
  // JWT
  JWT_INVALID_PAYLOAD = 'JWT_INVALID_PAYLOAD',
  JWT_INVALID_PLATFORM = 'JWT_INVALID_PLATFORM',
  JWT_INVALID_USERID = 'JWT_INVALID_USERID',
  MISSING_TOKEN = 'MISSING_TOKEN',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  REFRESH_EXPIRED_TOKEN = 'REFRESH_EXPIRED_TOKEN',
  ACCESS_EXPIRED_TOKEN = 'ACCESS_EXPIRED_TOKEN',
  INVALID_TOKEN = 'INVALID_TOKEN',
  NOT_BEFORE_CLAIM_TOKEN = 'NOT_BEFORE_CLAIM_TOKEN',
  REFRESH_TOKEN_REUSE = 'REFRESH_TOKEN_REUSE',

  // Auth
  RECOVER_PASSWORD_REQUEST_NOT_FOUND = 'RECOVER_PASSWORD_REQUEST_NOT_FOUND',
  RECOVER_PASSWORD_REQUEST_INVALID = 'RECOVER_PASSWORD_REQUEST_INVALID',
  RECOVER_PASSWORD_REQUEST_TIMED_OUT = 'RECOVER_PASSWORD_REQUEST_TIMED_OUT',
  RECOVER_PASSWORD_TOKEN_REUSE = 'RECOVER_PASSWORD_TOKEN_REUSE',

  RESET_PASSWORD_REQUEST_NOT_FOUND = 'RESET_PASSWORD_REQUEST_NOT_FOUND',
  RESET_PASSWORD_REQUEST_INVALID = 'RESET_PASSWORD_REQUEST_INVALID',
  RESET_PASSWORD_REQUEST_TIMED_OUT = 'RESET_PASSWORD_REQUEST_TIMED_OUT',
  RESET_PASSWORD_TOKEN_REUSE = 'RESET_PASSWORD_TOKEN_REUSE',

  ACCOUNT_VERIFICATION_REQUEST_NOT_FOUND = 'ACCOUNT_VERIFICATION_REQUEST_NOT_FOUND',
  ACCOUNT_VERIFICATION_REQUEST_INVALID = 'ACCOUNT_VERIFICATION_REQUEST_INVALID',
  ACCOUNT_VERIFICATION_REQUEST_TIMED_OUT = 'ACCOUNT_VERIFICATION_REQUEST_TIMED_OUT',
  ACCOUNT_VERIFICATION_TOKEN_REUSE = 'ACCOUNT_VERIFICATION_TOKEN_REUSE',

  // User
  USER_EMAIL_EXISTS = 'USER_EMAIL_EXISTS',
  USER_NOT_VERIFIED = 'USER_NOT_VERIFIED',
  USER_ALREADY_VERIFIED = 'USER_ALREADY_VERIFIED',
  USER_LOCKED = 'USER_LOCKED',
  USER_BLOCKED = 'USER_BLOCKED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_IDENTITY_NOT_FOUND = 'USER_IDENTITY_NOT_FOUND',

  // file structure
  PARENT_FOLDER_NOT_FOUND = 'PARENT_FOLDER_NOT_FOUND',
  PARENT_CANNOT_BE_FILE = 'PARENT_CANNOT_BE_FILE',
  ROOT_PARENT_FOLDER_NOT_FOUND = 'ROOT_PARENT_FOLDER_NOT_FOUND',
  ROOT_PARENT_CANNOT_BE_FILE = 'ROOT_PARENT_CANNOT_BE_FILE',
  INVALID_FILE_NAME = 'INVALID_FILE_NAME',
  FILE_STRUCTURE_NOT_FOUND = 'FILE_STRUCTURE_NOT_FOUND',
  FS_SAME_NAME = 'FS_SAME_NAME',
  FILE_STRUCTURE_IS_NOT_FILE = 'FILE_STRUCTURE_IS_NOT_FILE',
  FILE_STRUCTURE_IS_ALREADY_ENCRYPTED = 'FILE_STRUCTURE_IS_ALREADY_ENCRYPTED',
  FILE_STRUCTURE_ALREADY_EXISTS = 'FILE_STRUCTURE_ALREADY_EXISTS',

  // file structure bin
  FILE_STRUCTURE_BIN_NOT_FOUND = 'FILE_STRUCTURE_BIN_NOT_FOUND',
  FILE_STRUCTURE_BIN_CREATION_FAILED = 'FILE_STRUCTURE_BIN_CREATION_FAILED',

  // file structure encryption
  FS_ENCRYPTION_NOT_FOUND = 'FS_ENCRYPTION_NOT_FOUND',
  FS_ENCRYPTION_CREATE_FAILED = 'FS_ENCRYPTION_CREATE_FAILED',

  // user support
  USER_SUPPORT_NOT_FOUND = 'USER_SUPPORT_NOT_FOUND',
  USER_SUPPORT_MESSAGE_NOT_FOUND = 'USER_SUPPORT_MESSAGE_NOT_FOUND',
  USER_SUPPORT_NOT_PENDING = 'USER_SUPPORT_NOT_PENDING',

  // legals
  LEGAL_DOCUMENT_NOT_FOUND = 'LEGAL_DOCUMENT_NOT_FOUND',
  LEGAL_DOCUMENT_PARAGRAPH_NOT_FOUND = 'LEGAL_DOCUMENT_PARAGRAPH_NOT_FOUND',

  // Other
  EMAIL_OR_PASSWORD_INVALID = 'EMAIL_OR_PASSWORD_INVALID',
  MISSING_CURRENT_USER_PAYLOAD = 'MISSING_CURRENT_USER_PAYLOAD',
  PASSWORD_INVALID = 'PASSWORD_INVALID',
  NEW_PASSWORD_SAME = 'NEW_PASSWORD_SAME',
  WAIT_FOR_ANOTHER_DAY = 'WAIT_FOR_ANOTHER_DAY',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  HTTP_EXCEPTION = 'HTTP_EXCEPTION',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',

  //
  TESTING = 'TESTING',
  ASSET_ERROR = 'ASSET_ERROR',
  ONLY_ENCRYPTED_FILES_ALLOWED = 'ONLY_ENCRYPTED_FILES_ALLOWED',
  CLIENT_OR_INTERNAL_ERROR = 'CLIENT_OR_INTERNAL_ERROR',
  DOWNLOAD_ERROR = 'DOWNLOAD_ERROR',
}
