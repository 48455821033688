export enum FileMimeType {
  TEXT_PLAIN = 'TEXT_PLAIN',
  TEXT_MARKDOWN = 'TEXT_MARKDOWN',
  APPLICATION_JSON = 'APPLICATION_JSON',
  APPLICATION_XML = 'APPLICATION_XML',
  APPLICATION_PDF = 'APPLICATION_PDF',
  APPLICATION_OCTET_STREAM = 'APPLICATION_OCTET_STREAM',
  IMAGE_JPG = 'IMAGE_JPG',
  IMAGE_PNG = 'IMAGE_PNG',
  IMAGE_GIF = 'IMAGE_GIF',
  IMAGE_WEBP = 'IMAGE_WEBP',
  IMAGE_BMP = 'IMAGE_BMP',
  IMAGE_SVG = 'IMAGE_SVG',
  AUDIO_MPEG = 'AUDIO_MPEG',
  AUDIO_WAV = 'AUDIO_WAV',
  VIDEO_MP4 = 'VIDEO_MP4',
  VIDEO_MPEG = 'VIDEO_MPEG',
  VIDEO_WEBM = 'VIDEO_WEBM',
  VIDEO_QUICKTIME = 'VIDEO_QUICKTIME',
  OTHER = 'OTHER',
}
